import { proxy } from "valtio";

export interface State {
  companyName: string;
  secondaryColor: string;
};

export const initialState: State = {
  companyName: "IFI",
  secondaryColor: "#000",
};

export const state = proxy({ ...initialState });

export function reset() {
  Object.assign(state, initialState);
}
