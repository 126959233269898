//COMPANY NAME
export const companyName = "IFI Network";
export const landingWelcome = (name: string) => {
  return "Banking Platform";
};
export const assistanceContact =
  "For assistance please reach out to (801) 656-9652";

// PARAGRAPHS
export const DRAG_AND_DROP_HERE = "... or drag and drop your file here...";
export const ACCOUNT_PROCESS =
  "Our system will take 24 hours to process your account data once uploaded.";
export const LOAD_CLIENTS_CRM = "Load clients from any CRM output data";
export const ACCOUNTS_UPLOADS_TIPS = [
  "1. We support all CRM systems and file formats however we suggest CSV or Excel for fastest processing",
  "2. Data must at a minimum include legal account name, and email address",
  "3. The more information included the less information your clients require when submitting their IFI application",
];

//STATEMENT PDF
export const CUSTOMER_SERVICE_TITLE1 = "QUESTIONS?";
export const CUSTOMER_SERVICE_SUBTITLE1 = "You may call (801) 656-9652";
export const CUSTOMER_SERVICE_TITLE2 = "OR, YOU CAN WRITE:";
export const CUSTOMER_SERVICE_SUBTITLE2 = [
  "IFI Client Services",
  "2825 E Cottonwood Parkway,",
  "Suite 500",
  "Salt Lake City, Utah 84121",
];
export const CUSTOMER_SERVICE_TITLE3 = "IN CASE OF ERRORS: ";
export const CUSTOMER_SERVICE_SUBTITLE3_1 =
  "If you think your statement or record is wrong or if you need more information about a transfer on the statement or record, telephone us or write to us at the address shown in the Customer Service Information section on your statement as soon as possible. We must hear from you no later than 60 days after we sent you the first statement on which the error or problem appeared. You are entitled to remedies for error resolution for an electronic fund transfer in accordance with the Electronic Fund Transfer Act and federal Regulation E or in accordance with laws of the state where your account is located as may be applicable. Give us the following information: ";
export const CUSTOMER_SERVICE_SUBTITLE3_2 = [
  "1. Your name and account number.",
  "2. The dollar amount of the suspected error.",
  "3. Describe the error or the transfer you are unsure about and explain as clearly as you can why you believe there is an error or why you need more information.",
];
export const CUSTOMER_SERVICE_SUBTITLE3_3 =
  "We will investigate your complaint and will correct any error promptly.";
export const DISCLOSURES_TITLE1 = " The balances ";
export const DISCLOSURES_PARAGRAPH1 =
  "in HYBA products provide FDIC Insurance up to $250,000 per account. To confirm a HYBA bank is registered with the FDIC, you can view it through the following website: https://banks.data.fdic.gov/bankfind-suite/bankfind. The FDIC is an independent agency of the U.S. Government that protects depositor funds placed in FDIC-insured institutions.";
export const DISCLOSURES_TITLE2 = " Net Accrued Interest ";
export const DISCLOSURES_PARAGRAPH2 =
  "is the estimated amount of the accrued interest earned for the current month less any fees with respect to such interest. Net Accrued Interest cannot be withdrawn from the account until it is posted as a transaction to the account. There is no guarantee that the account holder will receive the full amount as stated. Net Accrued Interest is for informational purposes only.";

// ALERTS
export const SUCCESS = "Success";
export const WARNING = "Warning";
export const ERROR = "Error";
export const IF_FAILURE_TEXT =
  "If failure occurs, please provide consent for our technical leads to work with you directly on data issues.";

export const REQUIRED_FIELD_MISSING = "Please fill out this field";

export const UPLOAD_SUCCESS_MSG =
  "Your file is being processed. Please check back in 24 hours.";
export const UPLOAD_WARNING_MSG = "There may be an issue with this file.";
export const UPLOAD_TOO_BIG =
  "This file is too big to be uploaded. Max upload size is 9 megabytes.";
export const UPLOAD_ERROR_MSG =
  "There was a problem uploading your file. Please try again or contact us for support.";
export const DEFAULT_UPLOAD_COMMENT = "There are no comments for this file.";

export const SUCCESS_ONBOARDING_MSG = (type: "create" | "update") =>
  type === "create"
    ? "This account has been successfully created."
    : "This account has been successfully updated.";

export const ERROR_ONBOARDING_MSG = (type: "create" | "update") =>
  type === "create"
    ? "There was a problem creating the account."
    : "There was a problem updating the account.";

export const PERSONAL_URL = "Personal URL";
export const COPIED_URL = "URL Copied";
export const AVAILABLE_NOW = "Available Now:";
export const PENDING_TRANSACTIONS = "Pending Transactions:";

export const UPLOAD_SUCCESS = "Upload Success";
export const UPLOAD_WARNING = "Upload Warning";
export const UPLOAD_FAILURE = "Upload Failure";

export const IDLE_TITLE = "Idle Warning";
export const IDLE_MESSAGE =
  "It appears you are inactive. You will be logged out in 5 minutes";

export const SUCCESS_ADVISOR_CONFIG = `Successfully updated your Advisor Firm's Configuration`;
export const ERROR_ADVISOR_CONFIG = `There was a problem updating your Advisor Firm's Configuration`;

export const COPIED_TO_CLIPBOARD = "Copied to clipboard!";

//BUTTONS
export const BUTTON_FILTER_BY = "Filter By";
export const BUTTON_CANCEL = "Cancel";
export const BUTTON_APPLY = "Apply";

//FORMS
export const ACCOUNT_TYPE_TITLE = "Type of account";
export const PERSONAL_INFORMATION_TITLE = "Personal information for";
export const ENTITY_INFORMATION_TITLE = "Entity Information for";
export const PERSONAL_INFORMATION = "Personal Information";
export const PERSONAL_INFORMATION_JOINT_ACCOUNT_TITLE_PRIMARY =
  "Personal information: Primary person";
export const PERSONAL_INFORMATION_JOINT_ACCOUNT_TITLE_SECONDARY =
  "Personal information: Secondary person";
export const PERSONAL_INFORMATION_TRUST_ACCOUNT_REPRESENTATION_TITLE =
  "Account Owner Representation As Not A U.S. Employee Retirement Income Security Act Plan Asset*";
export const PERSONAL_INFORMATION_TRUST_ACCOUNT_REPRESENTATION =
  "The Account Owner and the person making the decision on behalf of such Account Owner to make a deposit in a FICA Account (if any) represents, warrants and certifies, by checking the box below, that on each date on which the Account Owner has a deposit in a FICA Account, the Account Owner is not subject to Title I of the Employee Retirement Income Security Act of 1974, as amended (“ERISA”), Section 4975(e)(1) of the Internal Revenue Code of 1986, as amended (the “Code”), or in the case of any governmental employee benefit plan, not subject to any law, statute, regulation, provision or other governing provision which is similar to Section 406 of ERISA or Section 4975 of the Code";
export const AGREE_STATEMENT = "I agree to the statement above.";
export const PERSONAL_INFORMATION_TRUST_ACCOUNT_AGREMENT = `The IFI (“IFI”) Program allows customers to direct IFI to deposit funds into one or more Depository Bank Programs (as defined below) via the convenience of a single custodial account (“IFI Account”). In opening an IFI Account, you agree to the Terms and Conditions set forth on your application sent separately.

The funds in your IFI Account will be held by The Northern Trust Company (“Northern Trust”) which will be acting as your custodian and as the custodian for the IFI program (the “IFI Program”). The Northern Trust Company, in its capacity as the IFI Program custodian, is referred to herein as the “IFI Custodian.” By opening an IFI Account, you are appointing IFI as your authorized agent as described herein.

Upon your instructions, IFI will direct the IFI Custodian to deposit funds from your IFI Account into one or more deposit accounts (each, a “Depository Account”) at selected banks (each a “Depository Bank”). Depository Account(s) will be in the name of one or more sub-custodians (each, as “Sub-Custodian”) acting as custodian for the persons or entities who are listed on its records.

Funds in the IFI Program may be deposited in Depository Banks which are not FDIC member banks, or in FDIC member banks in amounts beyond the applicable limits for FDIC deposit insurance coverage. FUNDS DEPOSITED THROUGH YOUR IFI ACCOUNT IN A DEPOSITORY ACCOUNT WITH A DEPOSITORY BANK WHICH IS NOT AN FDIC MEMBER BANK, OR DEPOSITED WITH AN FDIC MEMBER BANK IN AN AMOUNT IN EXCESS OF THE APPLICABLE LIMIT FOR FDIC DEPOSIT INSURANCE COVERAGE (CURRENTLY $250,000.00), ARE NOT INSURED OR GUARANTEED BY THE FEDERAL DEPOSIT INSURANCE CORPORATION OR ANY OTHER GOVERNMENT AGENCY.`;
export const ACCOUNT_ACCESS_ADVISOR_TITLE = "Advisor Representative";
export const ACCOUNT_ACCESS_ADVISOR_DISCLAIMER =
  "This advisor representative or team, will have view access to your account";
export const ACCOUNT_ACCESS_TITLE = "Withdrawal Instructions";
export const ACCOUNT_ACCESS_FOOTER_TITLE = "View Access For Your Advisor";
export const ACCOUNT_ACCESS_ADVISOR =
    "By submitting this application, you are giving your advisor access to view your account information. Please continue only if you agree to grant them access. Please reach out to your advisor for more information.";
export const ACCOUNT_ACCESS_NONADVISOR =
  "By submitting this application, you are giving your advisor access to view your account information. Please continue only if you agree to grant them access. Please reach out to your advisor for more information.";
export const TERMS_AND_CONDITIONS = `
The IFI (“IFI”) Program allows customers to direct IFI to deposit funds into one or more Depository Bank Programs (as
defined below) via the convenience of a single custodial account (“IFI Account”). In opening an IFI Account, you agree to
the Terms and Conditions set forth herein.

The funds in your IFI Account will be held by The Northern Trust Company (“Northern Trust”) which will be acting as your
custodian and as the custodian for the IFI program (the “IFI Program”). The Northern Trust Company, in its capacity as
the IFI Program custodian, is referred to herein as the “IFI Custodian.” By opening an IFI Account, you are appointing IFI
as your authorized agent as described herein.

Upon your instructions, IFI will direct the IFI Custodian to deposit funds from your IFI Account into one or more deposit
accounts (each, a “Depository Account”) at selected banks (each a “Depository Bank”). Depository Account(s) will be in
the name of one or more sub-custodians (each, as “Sub-Custodian”) acting as custodian for the persons or entities who
are listed on its records.

Funds in the IFI Program may be deposited in FDIC member banks in amounts beyond the applicable limits for FDIC
deposit insurance coverage. FUNDS DEPOSITED THROUGH YOUR IFI ACCOUNT IN A DEPOSITORY ACCOUNT WITH AN
FDIC MEMBER BANK IN AN AMOUNT IN EXCESS OF THE APPLICABLE LIMIT FOR FDIC DEPOSIT INSURANCE COVERAGE
(CURRENTLY $250,000.00), ARE NOT INSURED OR GUARANTEED BY THE FEDERAL DEPOSIT
INSURANCE CORPORATION OR ANY OTHER GOVERNMENT AGENCY.

1) Account Eligibility

The IFI Program is only open to participants which are U.S. Persons. For this purpose, a “U.S. Person” is (i) any
partnership, corporation or limited liability company organized or incorporated under the laws of the United States or
any state thereof, (ii) any trust as to which one or more natural persons reside in the United States, or a U.S. Person as
otherwise defined herein, has or have the authority as trustee to control all of the substantial decisions of the trust, and
(iii) any federal, state or local governmental unit, agency or authority located in the United States.

2) Appointment of IFI as your Agent

By opening an IFI Account, you are appointing IFI as your authorized agent to direct the IFI Custodian pursuant to these
Terms and Conditions (which IFI may modify by notice to you), and fully authorizing the IFI Custodian to follow all
instructions of IFI acting as your agent with respect to your funds and your IFI Account. See Section 18 “Changes to Terms
and Conditions”.

IFI will act as your agent in directing the IFI Custodian to deposit funds from your IFI Account into the selected Depository
Accounts at the Depository Banks. If you make a withdrawal request, IFI will act as your agent in directing the IFI
Custodian to withdraw funds from the Depository Accounts and crediting such funds to your IFI Account (unless
otherwise specified...see HYBA Collateralized). The IFI Custodian will transfer funds from your IFI Account to you as
directed by IFI, acting as your agent. IFI will also act as your agent to forward to the IFI Custodian the information needed
to maintain your IFI Account with the IFI Custodian.

The IFI Custodian has no responsibility or liability with respect to the actions or omissions of IFI; it has no duty to
oversee, monitor or supervise IFI, and has no duty to investigate, confirm, verify, or otherwise inquire into any instruction
it receives from IFI (or concerning the authority or accuracy thereof) with respect to your funds or your IFI Account. 

Beyond the information and instructions provided by you on your IFI Application, the IFI Custodian will not be privy to
instructions you may send to IFI from time to time.

IFI has the right, in its sole discretion, to appoint a different custodian for your IFI Account with prior notice.

3) Transfers and Interests

Interest rates paid on deposits in Depository Accounts are determined by the Depository Banks in their discretion based
on prevailing economic and business conditions and such rates may change at any time without notice. The interest rate
paid by any Depository Bank may fluctuate based on market conditions. The net interest earned on your deposits will be
set forth in your account statement.

Unless otherwise specified by the product individually, funds must be received by the IFI Custodian by 3:00 p.m. Eastern
Time (“Cut-Off”), in order for your IFI Account to receive same day credit. In your monthly account statements, IFI may
estimate the aggregate amount of accrued but unpaid interest less any IFI fee (the “IFI Fee”) with respect to such
interest, which amount cannot be withdrawn from your IFI Account until it is credited to the IFI Account. Any estimate of
net accrued interest on your monthly account statement is for informational purposes only.

The IFI Fee will be deducted from your IFI Account on the day interest from a Depository Account is credited to your IFI
Account. See Section 6 “Account Fees and Charges”.

The rate you earn on your deposits at any Depository Bank through the IFI Program may be higher or lower than the
rates available to depositors making non-IFI Program deposits with a Depository Bank or with rates offered by other
depository institutions in comparable accounts. You should compare the terms, interest rates, required minimum
amounts, charges and other features of the IFI Program with other accounts and investment alternatives. There is no
minimum period that your money must remain in the IFI Program.

Interest will accrue from the Business Day (as defined below) your funds are timely received in investable form by Cut-Off
to the IFI Custodian through the date you request those funds to be withdrawn. If your withdrawal request is received
prior to Cut-Off your IFI Account should generally be credited with good funds on the same Business Day (as defined
below); and for any withdrawal request received at Cut-Off or anytime thereafter, your IFI Account should generally be
fulfilled, with the withdrawal proceeds wired to an account that you designate, on the Business Day (as defined below)
following the day your withdrawal request is processed. See Section 4 “Withdrawals”.

Funds must be posted to your IFI Account by the Cut-Off on any Business Day in order for the IFI Custodian to initiate a
transfer of your funds to the Depository Banks on such day. If funds are received by the IFI Custodian after the Cut-Off on
any Business Day or funds are received on a non-Business Day, then the IFI Custodian will generally initiate a transfer of
your funds on the next Business Day, pursuant to instruction received from IFI.

The transfers may not occur within the timeframes described above, and none of IFI, the IFI Custodian or any
Sub-Custodian will be responsible for delays in the processing of funds transfers to or from your IFI Account or to or from
Depository Accounts, or between Depository Banks. In addition, in each instance described above, the ability of the IFI
Custodian to initiate any transfer of funds will be subject to any limitations that may be imposed by any time-zone and/or
local business day differences affecting the applicable Depository Bank(s). See Section 5 “Depository Bank Programs”
below.

IFI and the IFI Custodian reserve the right to take reasonable measures to verify any deposit or deposit request (but shall
not be under any duty to do so) before processing any deposit and shall not be liable for delays caused by such
verification measures.

4) Withdrawals

Withdrawals from your IFI Account can be made any Business Day. You may request a withdrawal from your IFI Account
by accessing our website and following the directions under the section entitled “Account Withdrawals.”

If you satisfactorily complete your withdrawal request before the Cut-Off on any Business Day, the withdrawal request
should, under normal conditions, be initiated and processed via transfer at the Depository Banks on such day. Please
reference Section 8 “Days of Operation” for additional detail on the IFI Program’s days of operation.

Your IFI Account will usually be credited with the withdrawal proceeds on the date set forth in Section 4 hereto (except in
the case of HYBA Collateralized program, which may differ). Thereafter, pursuant to IFI instruction, the IFI Custodian will
return the funds in your IFI Account to the account designated by you. If your withdrawal request is completed at or after
the Cut-Off on any Business Day, then your withdrawal request will be initiated on the following Business Day.

If you decide to close your IFI Account or request a complete withdrawal, you may have to wait a period of time for all of
the interest to be posted to your IFI Account since interest can only be credited to your IFI Account on the same day that
interest is credited by the Depository Banks to the Depository Accounts. Because Depository Banks do not generally post
interest to a Depository Account on a same day basis, there may be a delay between the date of your withdrawal request
and the date on which you receive all of the interest that accrued in the Depository Accounts to the effective date of
your withdrawal.

IFI and the IFI Custodian reserve the right to take reasonable measures to verify a withdrawal request (but neither of
them is under any duty to do so), and IFI and the IFI Custodian will not be held liable for any delays caused by such
verification measures. In the case of partial withdrawals from your IFI Account, IFI will direct the IFI Custodian as to the
Depository Bank(s) from which your withdrawal request will be processed. The IFI Custodian shall have no obligation or
liability for the selection of the Depository Bank(s) which will process any such withdrawal request.

IFI and the IFI Custodian will not be held liable for any delay, failure, or error by any Depository Bank in making payment
on any Depository Account.

5) Depository Bank Programs

Amounts in your IFI Account along with funds from other participants in the IFI Program (collectively, “IFI Depositors”)
will be deposited by the IFI Custodian at the direction of IFI into one or more Depository Bank Program(s). The
Depository Account(s) at each Depository Bank will be held in the name of the Custodian or Sub-Custodian. Each
Sub-Custodian will act as Custodian for the IFI Custodian which acts as Custodian for the IFI Depositors. IFI selects the
eligible Depository Banks for the IFI Depository Bank Programs and may include additional Depository Banks in the IFI
Depository Bank Program. If a Depository Bank rejects additional deposits or decides to return funds to the IFI Custodian
(or closes the Depository Accounts at the Depository Bank entirely), IFI will direct the IFI Custodian to transfer such funds
back to the participant unless directed otherwise.

Pursuant to federal Regulation D, Depository Banks may exercise the right to require seven (7) days’ notice before
permitting a transfer of funds out of a Depository Account.

All funds’ movements (whether involving deposits, transfers or withdrawals) will be subject to time-of-day and local
business day limitations, including limitations imposed by time-zone differences.

Your monthly account statement will indicate the Depository Bank Program(s) in which your IFI Account funds have been
deposited. Each Depository Bank Programs contain separate financial institutions. A Depository Bank could be accessible
through multiple Depository Bank Programs. You will be solely responsible for notifying IFI of any Depository Bank
Program(s) to which you wish your funds to be allocated for deposit. IFI in turn will be responsible for giving instruction
to the IFI Custodian. The IFI Custodian will have no responsibility or liability with respect to the selection, investigation, or
evaluation of any Depository Bank, and shall have no liability for the actions or omissions of IFI or any Depository Bank.
The IFI Custodian shall not be under any duty to investigate or reconcile (whether against your instruction to IFI or
otherwise) any instruction it receives from IFI. Customer may have access to one or more of the IFI Depository Bank
Programs.

    a. HYBA Program (“HYBA”)

    The Program offers depositors the opportunity to deposit funds with Depository Banks presented by IFI. Each
    Depository Bank will pay an interest rate to you on your deposits, with the interest rate solely determined by
    each Depository Bank. IFI provides you with a list of banks, along with the interest rates offered by each bank.
    You instruct IFI which Depository Bank(s) to deposit funds within each bank’s Cut-Off time, and the amounts you
    elect to deposit at the Program Bank, by sending funds to the program’s custodial account.

    The IFI Custodian then places, as instructed by you (via IFI, as your agent), the funds at the Depository Bank(s)
    selected by you, in the amounts instructed by you.

    Your funds are held at the Depository Bank(s) in an account titled in the name of the
    IFI Custodian for the benefit of its clients (“Deposit Account”). Deposit Account funds accrue interest at the
    interest rate determined by Program Bank, and Program Bank credits accrued interest to the Deposit Account on
    a periodic basis. Neither IFI nor Custodial Agent negotiates or sets the interest rates, Cut-Off times, terms, fees or
    conditions in connection with the Deposit Account. Additionally, IFI or Custodial Agent may only move your funds
    from the Deposit Account upon your instruction.

    HYBA Depository Accounts are subject to the terms of IFI Program Application. IFI and you, as the customer, shall
    be solely responsible for the approval of all Depository Banks to which you wish your funds to be allocated for
    deposit. The IFI Custodian shall have no responsibility for any risks associated with the creditworthiness or
    performance of any Depository Banks), or for the enforcement of rights or remedies against Depository Banks. In
    addition, the IFI Custodian (and any Sub-Custodian) is entitled, at its option and without any liability on its part,
    at any time or times, with notice to IFI, to revoke or terminate its agreement to provide custodial or
    sub-custodial services, as applicable, in connection with or with respect to any Depository Bank or Banks.

      i. HYBA Collateralized accounts will be held with Depository Bank(s) that have declared specified collateral
      on IFI account deposits. IFI will provide a separate addendum indicating the types of collateral used on the
      specified HYBA Collateralized deposits. Transactions will default to standard IFI transfer of funds (see Section
      4 & Section 5), unless requested by Depositor and approved by IFI.). If approved, Depositor will be able to
      deposit and withdraw funds directly into and out of collateralized Depository Bank(s). Changes in transfer of
      funds structure may lead to changes in deadline times.

    b. MDI Bank Programs

    The MDI Bank Programs offered by IFI, allows customers the ability to place their deposits with Minority
    Depository Institutions and a highly rated Sponsor Bank. The money from your IFI Account will be deposited at
    Minority Depository Institution Network Partners (“Network Partners”) in a manner to maximize FDIC and/or
    NCUA insurance coverage, which is fully guaranteed by the full faith and credit of the U.S. government. Both the
    FDIC Standard Maximum Deposit Insurance Amount and the NCUA Share Insurance Fund’s maximum insurance
    amount (“SMDIA/SIFMIA”) are currently $250,000 per depositor, per insured depository. IFI provides Network
    Partners with access to deposits, not to exceed $250,000 per depositor. Excess deposits will be placed with the
    highly rated sponsor bank.

    c. IFI Cash

    The IFI Cash program allows customers to receive additional protection on their money by placing deposits in a
    manner to maximize FDIC and/or NCUA insurance coverage, which is fully guaranteed by the full faith and credit
    of the U.S. government. Both the FDIC Standard Maximum Deposit Insurance Amount and the NCUA Share
    Insurance Fund’s maximum insurance amount (“SMDIA/SIFMIA”) are currently $250,000 per depositor, per
    insured depository. IFI provides Network Partners with access to deposits, not to exceed $250,000 per depositor.

6) Account Fees and Charges

The IFI Fee will be determined by IFI and will be deducted from your IFI Account on the date on which interest from a
Depository Bank Program is credited to your IFI Account. The IFI Fee charged may vary between Depository Bank
Programs but will never exceed the gross interest earned by your IFI Account from the Depository Bank Program(s) at
which the interest is earned. Neither the IFI Custodian nor any Sub-Custodian will charge you a separate fee for holding
your IFI Account but will be paid separately by IFI.

7) Account Statements

You will receive a monthly account statement from IFI via our website provided by IFI. IFI will send you an e-mail when
your account statement is available for viewing. You will be required to use your online access ID and password for you to
view, print or save e-statement account information.

By signing the IFI Account Custody Agreement, you specifically consent to electronic delivery of your periodic account
statements and any other disclosures relating to your account(s), either by forwarding such statements and disclosures
or by providing a notification of availability and links to such statements or disclosures, to the e-mail address provided to
IFI. You are responsible for notifying IFI of changes in the e-mail address to be used for such purposes.

All activity with respect to your IFI Account, including your account balance through one or more Depository Bank
Programs at each Depository Bank and the net interest earned and transaction history, will appear on your account
statement. The account statement will also include the aggregate of your opening and closing balances in your IFI
Account. You will not receive a separate statement from the Depository Banks.

You should download and retain all account statements. You must notify IFI immediately of any
discrepancies noted on your account statement.

8) Days of Operation

Customer service for the IFI Program is open Monday through Friday, 8:30 a.m. until 5:30 p.m. (Eastern Time) except for
holidays observed by the United States Federal Reserve System which are currently listed at
https://www.federalreserve.gov/aboutthefed/k8.htm, or holidays observed by foreign bank regulators of Depository
Banks headquartered outside the United States (each day the IFI Program is open is referred to herein as a “Business Day”). Deposits to, and transfers or withdrawals from, your IFI Account may only be made on days that the IFI Custodian
and any related Sub-Custodian and Depository Bank are open for business.

The IFI’s Business Days and business hours may not coincide with the Business Days and business hours of the Depository
Banks (and may be adversely affected if located in different time-zones). In such instances IFI and the IFI Custodian will
process your transaction per usual, however delays in the settlement of the transaction may occur if the Depository
Bank(s) which is/are affected by the transaction are not open for business (or is/are located in different time-zone(s)).
Your IFI representative will provide you with a schedule of non-Business Days for each bank to which your funds are
allocated if such schedule differs from that of the IFI Program.

9) FDIC Deposit Insurance

Funds that are deposited in the IFI Program in a Depository Account with a Depository Bank in an amount in excess of
the applicable limit for FDIC Deposit Insurance Coverage (Currently $250,000.00), are not guaranteed by the FDIC or any
government agency. As a result, in the event of a financial failure of any such Depository Bank, your funds on deposit in a
Depository Account with such Depository Bank will be at risk.

Assets that are held by the IFI Custodian in the IFI Account (such as deposits in the Depository Accounts at Depository
Banks) for safekeeping in custody are not considered assets of the IFI Custodian. Your rights in the custodial assets held in
the IFI Account will remain your property, whether such assets are registered in the IFI Custodian’s name, a
Sub-Custodian’s name or in nominee's name. The IFI Custodian is simply acting as your custodian, holding assets in the
IFI Account on your behalf, subject to instruction of IFI acting as your agent, and performing duties such as collecting
interest from the Depository Banks, pursuant to instruction received from IFI.

10) Tax Withholding and Reporting

The IFI Custodian may be required to withhold U.S. federal income tax at the prevailing rate on all interest payable to
certain IFI Depositors who fail to provide their correct taxpayer identification number or to make required certifications,
or who have been notified by the Internal Revenue Service that they are subject to backup withholding. Interest earned
on accounts held by entities (individuals or corporations) that are neither citizens nor residents of the United States,
except for Canadian residents, and who have provided required certifications and complied with other registration and
reporting requirements that may be applicable, generally are not subject to withholding tax. Where required by
applicable law, each IFI Depositor will be issued a Form 1099-INT which will set forth the net interest earned (i.e., interest
after fees) by an IFI Depositor from the Depository Banks.

11) Transferability; Assignment

The ownership of an IFI Account may not be transferred by you except with the express written consent of the IFI
Custodian and IFI or otherwise by operation of law. Any purported transfer shall not be binding on the IFI Custodian, IFI
or the Depository Banks until sufficient documentation has been received.

12) Termination; Closing of Account

IFI may, at its sole discretion, and without any prior notice, terminate your participation in the IFI Program and cause the
IFI Custodian to return funds in your IFI Account to your bank/brokerage account of record.

13) Clients Introduced by Third Parties

Under an agreement with the entity (“Third Party”) that introduced you to IFI (the “Introducing Agreement”), IFI has
agreed that the Third Party may introduce prospective clients to participate in its Programs. The Third Party is not
employed by or affiliated in any way with IFI. If you deposit money in IFI Programs because of a Third Party’s
introduction, IFI may pay the Third Party a fee in an amount determined by the Third Party and communicated to IFI
monthly (the “Referral Fee”). In such instances, IFI may add the Referral Fee to the amount that IFI would otherwise
charge you for participating in the IFI Program. Each prospective client introduced by the Third Party to IFI and who
deposits money in an IFI Account may receive the same or different monthly account yield; however, other persons who
deposit money in the IFI Program, whether introduced to IFI by another party or by depositing money directly with IFI,
may receive greater or lesser account yields. If applicable, the Third Party will disclose to you the Referral Fee with
respect to each month upon request. For as long as you have a IFI Account relationship with IFI, IFI may pay the Third
Party a Referral Fee. This fee covers any expenses for introduction efforts, as well as, including but not limited to, the
expenses of any ongoing, client consultations. In no event will the services include providing cash management services
on behalf of IFI in any manner.

14) Ordinary Care

Any liability for any act or failure to act by IFI, the IFI Custodian, a Sub-Custodian or any Depository Bank or any delay by
such party beyond time limits prescribed by law or permitted by these Terms and Conditions is excused if caused by your
negligence, interruption of communication facilities, suspension of payments by another financial institution, war,
emergency conditions or other circumstances beyond the control of such party, provided such party exercised such
diligence as such circumstances would normally require. You agree that any act or omission by IFI, the IFI Custodian, a
Sub-Custodian, or any Depository Bank in reliance upon or in accordance with the rules and regulations of the Uniform
Commercial Code, any NACHA or other clearinghouse rules, or any rule or regulation of any state or federal agency
having jurisdiction over such party shall constitute ordinary care. In no event shall IFI, the IFI Custodian, a Sub-Custodian
or any Depository Bank be liable for any type of indirect, special, consequential, or punitive damages, even if we are
aware of the potential for such damages.

15) Personal Information

You agree that IFI, the IFI Custodian and their service providers will share information concerning you and your accounts
in connection with providing the services contemplated by these Terms and Conditions and may disclose information
about your IFI Account to any affiliate in accordance with IFI’s and the IFI Custodian’s Privacy Policy and otherwise as
permitted by law. You agree that IFI, the IFI Custodian and their service providers may obtain such information as may be
necessary for legitimate business needs in connection with the operation of the IFI Program. For information regarding
the collection, processing and use of your personal information and your rights to limit the use and disclosure of such
information, you should refer to the Privacy Policy available at www.ifinetwork.com.

16) Legal Process; Indemnification

You agree that IFI, the IFI Custodian, a Sub-Custodian and the Depository Banks may comply with any writ of attachment,
execution, garnishment, tax levy, restraining order, subpoena, warrant or other legal process, which such party in good
faith believes to be valid. IFI or the IFI Custodian or Sub-Custodian may notify you of such process by telephone,
electronically or in writing. If IFI or the IFI Custodian or Sub-Custodian is not fully reimbursed for its records research,
photocopying and handling costs (including its internal counsel costs) by the party that served the process, IFI or the IFI
Custodian or Sub-Custodian may charge such costs to your IFI Account. You agree to indemnify, defend, release from 
liability, and hold IFI, the IFI Custodian, a Sub-Custodian and the Depository Banks harmless from all actions, claims,
liabilities, losses, costs, attorney’s fees, and damages associated with their compliance with any process that such party
believes in good faith to be valid. You further agree that IFI, the IFI Custodian, a Sub-Custodian and the Depository Banks
may honor legal process that is served personally, by mail, or by facsimile transmission at any of their respective offices
(including locations other than where the funds, records or property sought is held), even if the
law requires personal delivery at the office where your IFI Account records are maintained.

17) Confidentiality and Ownership of the IFI Program

You agree (a) not to disclose the identity of Depository Banks in the IFI Program to any person or entity other than to
your affiliates, officers, directors, employees and professional advisors who need access to such information, and (b) that
you will not contact any of the Depository Banks about IFI, the IFI Custodian, any Sub-Custodian or the IFI Program
without IFI’s prior written consent. You also agree that IFI does and will at all times own all intellectual property rights in
and to any works of authorship, moral rights, copyrights, trademarks, service marks, patents, designs, trade secrets,
computer programs and algorithms relating to the IFI Program, including without limitation, the IFI Program documents
and the IFI websites, and that you will not use any such information, documents or property rights for any purpose other
than as a participant in the IFI Program.

18) Governing Law

These Terms and Conditions shall be governed by and subject to applicable federal laws and, to the extent not
preempted by federal law, the laws of the State of Utah without regard to its conflict of law provisions. Unless otherwise
provided herein, IFI, the IFI Custodian, a Sub-Custodian and the Depository Banks may comply with applicable
clearinghouse, Federal and correspondent bank rules in processing transactions for your IFI Account. You agree that
neither IFI nor the IFI Custodian or Sub-Custodian is required to notify you of a change in those rules, except to the
extent required by law.

19) Changes to Terms and Conditions

By opening an IFI Account, you agree to the Terms and Conditions set forth herein, which have been attached to your IFI
Custody Account Agreement (“Applicable Terms and Conditions”). You also agree that the applicable Terms and
Conditions is the only version of the IFI Program Terms and Conditions which govern your IFI Account. IFI may make
material changes to these Terms and Conditions at any time upon 15 days prior notice to you or on such shorter notice as
may be set forth herein. By opening an IFI Account, you agree to the Terms and Conditions set forth herein.

All questions regarding your IFI Account should be directed to IFI or to your advisor.

IFI IS SOLELY RESPONSIBLE FOR THE CONTENT OF THESE TERMS AND CONDITIONS. THE IFI CUSTODIAN DOES NOT
CONFIRM, REPRESENT OR WARRANT THE ACCURACY OR COMPLETENESS OF ANY OF THE STATEMENTS CONTAINED
HEREIN.
`;
export const AGREE_TERMS_AND_CONDITIONS =
  "I agree to the Terms and Conditions.";
export const SIGNATURE_TITLE = "Certification";
export const SIGNATURE_SUBTITLE =
  "I agree that by clicking “Submit”, and entering my name and date, I am providing the legal equivalent of my handwritten signature and that doing so shows my intent to sign the new account application and certification. I agree to print and/or save a copy of this certification for my records. This binding contract is effective as of today’s date, and is binding on myself, any joint account holder, and any successor account. By pressing the Submit button, a the new account will be created.";
export const IFI_NETWORK_URL = ".ifi.network";
export const CODE_INPUT = "Code here";

//EMBED CODE
export const GET_EMBED_CODE_BUTTON = (url: string, color: string) => `
<a href="${url}" target="_blank">
<button style="width: 134px; height: 32px; padding: 5px 23px 4px;
  border: none; border-radius: 3px; background-color: ${color};
  cursor: pointer;"
>
<span style="width: 58px; height: 22px; font-size: 16px; font-weight: 600;
  font-stretch: normal; font-style: normal; line-height: normal;
  letter-spacing: normal; text-align: center; color: #ffffff;"
>IFI Network</span>
</button>
</a>`;

//TRANSFER
export const TRANSFER_SUBTITLE =
  "Always double check that your source account has enough funds to cover your transaction.";
