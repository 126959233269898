import { useMutation } from '@apollo/client';
import { ChangeEvent, useContext, useState } from 'react';
import AccountAccessForm from '../../../../components/forms/accountAccess/accountAccess';
import AccountTypeForm from '../../../../components/forms/accountType/accountType';
import PersonalInformationForm from '../../../../components/forms/personalInformation/personalInformation';
import SignatureForm from '../../../../components/forms/signature/signature';
import TermsConditionsForm from '../../../../components/forms/termsConditions/termsConditions';
import { LoadingModalContext } from '../../../../components/loadingModal/loadingModalContext';
import StepperContainer from '../../../../components/stepper/stepperContainer';
import { triggerAlert } from '../../../../components/toast/toast';
import { uploadFileToBucket } from '../../../../components/uploadFile/uploadFile.service';
import { FormTypes } from '../../../../shared/interfaces/formTypes.enum';
import {
  ISelfApplyFields,
  IStepCanAdvance,
} from '../../../../shared/interfaces/iform';
import { formatFormInput } from '../../../../shared/utils/formUtils';
import {
  ERROR,
  ERROR_ONBOARDING_MSG,
  SUCCESS,
  SUCCESS_ONBOARDING_MSG,
} from '../../../../shared/utils/textConstants';
import { SUBMIT_SELF_APPLY } from './selfApplyWorkflow.service';
import SelfApplyConfirmation from '../selfApplyConfirmation/selfApplyConfirmation';
import moment from 'moment';
import * as Sentry from "@sentry/react";

const selfApplyFormDefault: ISelfApplyFields = {
  accountType: "",
  firstName: "",
  lastName: "",
  ssn: "",
  dateOfBirth: "",
  phoneNumber: "",
  email: "",
  address: "",
  firstName2: "",
  lastName2: "",
  ssn2: "",
  dateOfBirth2: "",
  phoneNumber2: "",
  email2: "",
  address2: "",
  accountAccess: "",
  advisorId: "",
  bankABA: "",
  beneficiaryAccountName: "",
  beneficiaryAccountNumber: "",
  ffcffbName: "",
  ffcffbName2: "",
  entityName: "",
  taxId: "",
  accountTitle: "",
  trustDocumentURL: "",
  signerName: "",
  signDate: "",
  entityEmail: "",
  zipCode: "",
  city: "",
  state: "",
};

const stepCanAdvanceDefault: IStepCanAdvance = {
  typeOfAccount: false,
  personalInformation: false,
  accountAccess: false,
  termsConditions: false,
  signature: false,
};

const SelfApplyWorkflow = () => {
  const [formFields, setFormFields] =
    useState<ISelfApplyFields>(selfApplyFormDefault);
  const [stepCanAdvance, setStepCanAdvance] = useState<IStepCanAdvance>(
    stepCanAdvanceDefault
  );
  const [submitSelfApplyForm] = useMutation(SUBMIT_SELF_APPLY);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const { setOpenModal } = useContext(LoadingModalContext);

  const handleSetFileToUpload = (file: File) => {
    setFileToUpload(file);
  };

  const handleValueChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    type?: FormTypes
  ) => {
    e.preventDefault();
    const label = e.target.name;
    const value = e.target.value;
    setFormFields((prevForm) => {
      return { ...prevForm, [label]: formatFormInput(type, value) };
    });
  };

  const clearFormFields = () => {
    setFormFields(selfApplyFormDefault);
  };

  const handleSetStepCanAdvance = (id: string, flag: boolean) => {
    setStepCanAdvance((prevState) => {
      return { ...prevState, [id]: flag };
    });
  };

  const submit = async () => {
    let bucketRes:any;
    try {
      setOpenModal(true);
      bucketRes =
        fileToUpload && (await uploadFileToBucket(fileToUpload as File, 'trust'));
      formFields.signDate = moment()
        .format('YYYY-MM-DD HH:mm:ss.SSS')
        .toString();

      await submitSelfApplyForm({
        variables: {
          onboardingForm: {
            ...formFields,
            trustDocumentURL: bucketRes?.uploadUrl,
          },
        },
      });
      setOpenModal(false);
      triggerAlert({
        type: 'success',
        title: SUCCESS,
        msg: SUCCESS_ONBOARDING_MSG('create'),
      });
      setSubmitSuccess(true);
    } catch (e) {
      Sentry.captureException(e,{
        extra:{
          variables:JSON.stringify({
            onboardingForm: {
              ...formFields,
              trustDocumentURL: bucketRes?.uploadUrl,
            },
          })
        }
      })
      console.error(e);
      setOpenModal(false);
      triggerAlert({
        type: 'error',
        title: ERROR,
        msg: ERROR_ONBOARDING_MSG('create'),
      });
    }
  };

  return (
    <>
      {submitSuccess ? (
        <SelfApplyConfirmation hildaAccountInfo={{ ...formFields }} />
      ) : (
        <StepperContainer
          steps={[
            {
              label: 'Type of account',
              form: (
                <AccountTypeForm
                  id="typeOfAccount"
                  formFields={formFields}
                  handleSetStepCanAdvance={handleSetStepCanAdvance}
                  handleValueChange={handleValueChange}
                />
              ),
              buttonBack: false,
              nextValidated: stepCanAdvance.typeOfAccount,
            },
            {
              label: 'Personal information',
              form: (
                <PersonalInformationForm
                  id="personalInformation"
                  formFields={formFields}
                  type={formFields.accountType}
                  handleSetStepCanAdvance={handleSetStepCanAdvance}
                  handleValueChange={handleValueChange}
                  handleSetFileToUpload={handleSetFileToUpload}
                />
              ),
              buttonBack: false,
              nextValidated: stepCanAdvance.personalInformation,
            },
            {
              label: 'Account access',
              form: (
                <AccountAccessForm
                  id="accountAccess"
                  formFields={formFields}
                  isAdvisor={false}
                  handleSetStepCanAdvance={handleSetStepCanAdvance}
                  handleValueChange={handleValueChange}
                />
              ),
              buttonBack: true,
              nextValidated: stepCanAdvance.accountAccess,
            },
            {
              label: 'Terms and conditions',
              form: (
                <TermsConditionsForm
                  id="termsConditions"
                  handleSetStepCanAdvance={handleSetStepCanAdvance}
                />
              ),
              buttonBack: true,
              nextValidated: stepCanAdvance.termsConditions,
            },
            {
              label: 'Signature',
              form: (
                <SignatureForm
                  id="signature"
                  formFields={formFields}
                  handleSetStepCanAdvance={handleSetStepCanAdvance}
                  handleValueChange={handleValueChange}
                />
              ),
              buttonBack: true,
              nextValidated: stepCanAdvance.signature,
            },
          ]}
          startOver={true}
          clearFormFields={clearFormFields}
          handleSubmit={submit}
          submitSuccess={submitSuccess}
        />
      )}
    </>
  );
};

export default SelfApplyWorkflow;
