import { useMutation } from '@apollo/client';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GET_ACCOUNTS_BY_STATUS_FOR_ADVISOR } from '../../../../components/accountsSearch/accountsSearch.service';
import AccountAccessForm from '../../../../components/forms/accountAccess/accountAccess';
import PersonalInformationForm from '../../../../components/forms/personalInformation/personalInformation';
import SignatureForm from '../../../../components/forms/signature/signature';
import TermsConditionsForm from '../../../../components/forms/termsConditions/termsConditions';
import { LoadingModalContext } from '../../../../components/loadingModal/loadingModalContext';
import StepperContainer from '../../../../components/stepper/stepperContainer';
import { triggerAlert } from '../../../../components/toast/toast';
import { uploadFileToBucket } from '../../../../components/uploadFile/uploadFile.service';
import { UserContext } from '../../../../shared/context/userContext';
import { FormTypes } from '../../../../shared/interfaces/formTypes.enum';
import {
  IHildaPendingClientFields,
  IStepCanAdvance,
} from '../../../../shared/interfaces/iform';
import { IHilda } from '../../../../shared/interfaces/ihilda.interface';
import { getPath, RouteName } from '../../../../shared/routes/routeList';
import { formatFormInput } from '../../../../shared/utils/formUtils';
import {
  ERROR,
  ERROR_ONBOARDING_MSG,
  SUCCESS,
  SUCCESS_ONBOARDING_MSG,
} from '../../../../shared/utils/textConstants';
import { SUBMIT_PENDING_CLIENT } from './hildaPendingClientWorkflow.service';
import * as Sentry from "@sentry/react";

const hildaPendingClientFormDefault: IHildaPendingClientFields = {
  accountType: '',
  firstName: '',
  lastName: '',
  ssn: '',
  dateOfBirth: '',
  phoneNumber: '',
  email: '',
  address: '',
  firstName2: '',
  lastName2: '',
  ssn2: '',
  dateOfBirth2: '',
  phoneNumber2: '',
  email2: '',
  address2: '',
  accountAccess: '',
  bankABA: '',
  beneficiaryAccountName: '',
  beneficiaryAccountNumber: '',
  ffcffbName: '',
  ffcffbName2: '',
  entityName: '',
  taxId: '',
  accountTitle: 'individual',
  trustDocumentURL: '',
  signerName: '',
  signDate: '',
  entityEmail: '',
};

const stepCanAdvanceDefault: IStepCanAdvance = {
  personalInformation: true,
  accountAccess: true,
  signature: false,
};

interface IProps {
  hildaAccountInfo: IHildaPendingClientFields;
  userIds: { userId: number; userId2: number };
  hildaId: string;
}

const HildaPendingClientWorkflow = (props: IProps) => {
  const history = useHistory();
  const { userData } = useContext(UserContext);
  const { hildaAccountInfo, hildaId, userIds } = props;
  const [formFields, setFormFields] = useState<IHildaPendingClientFields>(
    hildaPendingClientFormDefault
  );
  const [stepCanAdvance, setStepCanAdvance] = useState<IStepCanAdvance>(
    stepCanAdvanceDefault
  );

  const [submitPendingClient] = useMutation(SUBMIT_PENDING_CLIENT, {
    refetchQueries: [
      GET_ACCOUNTS_BY_STATUS_FOR_ADVISOR,
      'AccountsByHildaStatus',
    ],
  });

  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [fileToUpload, setFileToUpload] = useState<File>();
  const { setOpenModal } = useContext(LoadingModalContext);

  const handleSetFileToUpload = (file: File) => {
    setFileToUpload(file);
  };

  useEffect(() => {
    if (hildaAccountInfo) setFormFields(hildaAccountInfo);
  }, [hildaAccountInfo]);

  const handleValueChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    type?: FormTypes
  ) => {
    e.preventDefault();
    const label = e.target.name;
    const value = e.target.value;
    setFormFields((prevForm) => {
      return { ...prevForm, [label]: formatFormInput(type, value) };
    });
  };

  const handleSetStepCanAdvance = (id: string, flag: boolean) => {
    setStepCanAdvance((prevState) => {
      return { ...prevState, [id]: flag };
    });
  };

  const clearFormFields = () => {
    setFormFields(hildaPendingClientFormDefault);
  };

  const submit = async () => {
    try {
      setOpenModal(true);
      const bucketRes =
        fileToUpload && (await uploadFileToBucket(fileToUpload, 'trust'));
      const res = await submitPendingClient({
        variables: {
          userIds,
          hildaId,
          onboardingForm: {
            ...formFields,
            trustDocumentURL: bucketRes?.uploadUrl,
          },
        },
      });
      setOpenModal(false);
      const hilda: IHilda = res.data.submitPendingHildaForClient.hilda;
      triggerAlert({
        type: 'success',
        title: SUCCESS,
        msg: SUCCESS_ONBOARDING_MSG('update'),
      });
      setSubmitSuccess(true);
      setTimeout(
        () =>
          history.push(
            getPath(RouteName.HildaDetails, {
              advisorFirmId: userData.user.advisorFirmId,
              hildaAccountId: hildaId,
              hildaStatusId: hilda.statusId,
              hildaSubmitted: hilda.submitted.toString(),
            })
          ),
        500
      );
    } catch (e) {
      Sentry.captureException(e)
      console.error(e);
      setOpenModal(false);
      triggerAlert({
        type: 'error',
        title: ERROR,
        msg: ERROR_ONBOARDING_MSG('update'),
      });
    }
  };
  return (
    <StepperContainer
      steps={[
        {
          label: 'Personal information',
          form: (
            <PersonalInformationForm
              id="personalInformation"
              formFields={formFields}
              type={formFields.accountType}
              handleSetStepCanAdvance={handleSetStepCanAdvance}
              handleValueChange={handleValueChange}
              handleSetFileToUpload={handleSetFileToUpload}
            />
          ),
          buttonBack: false,
          nextValidated: stepCanAdvance.personalInformation,
        },
        {
          label: 'Account access',
          form: (
            <AccountAccessForm
              id="accountAccess"
              formFields={formFields}
              isAdvisor={false}
              handleSetStepCanAdvance={handleSetStepCanAdvance}
              handleValueChange={handleValueChange}
            />
          ),
          buttonBack: true,
          nextValidated: stepCanAdvance.accountAccess,
        },
        {
          label: 'Terms and conditions',
          form: (
            <TermsConditionsForm
              id="termsConditions"
              handleSetStepCanAdvance={handleSetStepCanAdvance}
            />
          ),
          buttonBack: true,
          nextValidated: stepCanAdvance.termsConditions,
        },
        {
          label: 'Signature',
          form: (
            <SignatureForm
              id="signature"
              formFields={formFields}
              handleSetStepCanAdvance={handleSetStepCanAdvance}
              handleValueChange={handleValueChange}
            />
          ),
          buttonBack: true,
          nextValidated: stepCanAdvance.signature,
        },
      ]}
      startOver={true}
      clearFormFields={clearFormFields}
      handleSubmit={submit}
      submitSuccess={submitSuccess}
    />
  );
};

export default HildaPendingClientWorkflow;
