import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import { ChangeEvent, useEffect, useState } from 'react';
import { AccountAccess } from '../../../shared/interfaces/accountAccess.enum';
import { FormTypes } from '../../../shared/interfaces/formTypes.enum';
import {
  Errors,
  IAccountAccessFields,
  Types,
} from '../../../shared/interfaces/iform';
import { isFormInputValid } from '../../../shared/utils/formUtils';
import {
  ACCOUNT_ACCESS_TITLE,
  ACCOUNT_ACCESS_ADVISOR,
  ACCOUNT_ACCESS_FOOTER_TITLE,
  ACCOUNT_ACCESS_NONADVISOR,
  ACCOUNT_ACCESS_ADVISOR_TITLE,
  ACCOUNT_ACCESS_ADVISOR_DISCLAIMER
} from "../../../shared/utils/textConstants";
import { useStyles } from './accountAccess.styles';
import { GET_ADVISORS_BY_SUBDOMAIN, getSubdomainFromURL } from './accountAccess.service';
import { useQuery } from '@apollo/client';
import Loading from '../../loading/loading';
import { IUser } from '../../../shared/interfaces/iuser.interface';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const errorsDefault: Errors = {
  advisorId: false,
  bankABA: false,
  beneficiaryAccountName: false,
  beneficiaryAccountNumber: false,
};

const types: Types = {
  bankABA: FormTypes.BankABA,
  advisorId: FormTypes.Text,
  beneficiaryAccountName: FormTypes.Text,
  beneficiaryAccountNumber: FormTypes.AccountNumber,
};

interface IProps {
  id: string;
  formFields: IAccountAccessFields;
  isAdvisor: boolean;
  handleSetStepCanAdvance: (id: string, flag: boolean) => void;
  handleValueChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    type?: FormTypes
  ) => void;
}

const AccountAccessForm = (props: IProps) => {
  const {
    id,
    formFields,
    isAdvisor,
    handleSetStepCanAdvance,
    handleValueChange,
  } = props;
  const [errors, setErrors] = useState(errorsDefault);
  const [advisors, setAdvisors] = useState<Array<IUser>>([])
  const classes = useStyles();
  const advisorSubdomain = getSubdomainFromURL();

 const { data: advisorsData, loading } = useQuery(GET_ADVISORS_BY_SUBDOMAIN, {
   variables: { advisorSubdomain },
 });

 useEffect(() => {
    if (!advisorsData || advisorsData.advisorsBySubdomain.length === 0)
      return;

    setAdvisors(advisorsData.advisorsBySubdomain);
 }, [advisorsData])

  useEffect(() => {
    validateSelection();
    // eslint-disable-next-line
  }, [formFields.accountAccess, formFields.advisorId]);

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    const label = e.target.name;
    const value = e.target.value;
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        [label]: !isFormInputValid(types[label], value),
      };
    });
    validateNextStep();
  };

  const validateSelection = () => {
    if (
      formFields.accountAccess === AccountAccess.ACH &&
      (advisors.length > 0 && !!formFields.advisorId )
    ) {
      handleSetStepCanAdvance(id, true);
    } else if(advisors.length === 0 && formFields.accountAccess === AccountAccess.ACH) {
      handleSetStepCanAdvance(id, true)
    } else {
      handleSetStepCanAdvance(id, false);
    }
  };

  const validateNextStep = () => {
    const isFormValid =
      Object.entries(types).filter(
        ([key, value]) => !isFormInputValid(value, formFields[key])
      ).length === 0;
    if (isFormValid) {
      handleSetStepCanAdvance(id, true);
    } else {
      handleSetStepCanAdvance(id, false);
    }
  };

  const getEllipsisText = (text: string) => {
    const size = 50;
    const maxSize = size - 10;
    if (text.length > size) {
      return (
        text.substring(0, maxSize) +
        "..." +
        text.substring(text.length - 10, text.length)
      );
    }
    return text;
  };

  if(loading || !advisorsData)
    return <Loading isLoading />

  return (
    <div>
      {advisors.length > 0 && (
        <>
          <div className={classes.title}>{ACCOUNT_ACCESS_ADVISOR_TITLE}*</div>

          <FormControl
            className={classes.wireTextField}
            variant="filled"
            size="small"
          >
            <Select
              id="advisorId"
              name="advisorId"
              IconComponent={ExpandMoreIcon}
              disableUnderline
              onChange={(e) =>
                handleValueChange(
                  e as ChangeEvent<HTMLSelectElement>,
                  types[FormTypes.advisorId]
                )
              }
              className={classes.advisorSelectInput}
              value={formFields.advisorId}
              placeholder="Select an advisor"
            >
              {advisors.map((user) => (
                <MenuItem
                  key={user.id}
                  value={user.id}
                  className={classes.menuItem}
                >
                  {getEllipsisText(`${user.firstName} ${user.lastName}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography className={classes.colorTextGrey}>
            {ACCOUNT_ACCESS_ADVISOR_DISCLAIMER}
          </Typography>
          <hr className={classes.divider} />
        </>
      )}

      <div className={classes.title}>{ACCOUNT_ACCESS_TITLE}</div>
      <br />
      <div className={classes.instructions}>
        <Typography className={classes.text}>Remember:</Typography>
        <ol className={classes.list}>
          <li>
            <b>ACH</b> are safer.
          </li>
          <li>
            <b>Wires</b> may incur fees at your receiving bank and should be
            used only with larger balance accounts or brokerage accounts.
          </li>
        </ol>
      </div>
      <FormControl className={classes.radioGroup} component="fieldset">
        <RadioGroup
          aria-label="accountAccess"
          id="accountAccess"
          name="accountAccess"
          value={formFields.accountAccess}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
        >
          <FormControlLabel
            value={AccountAccess.ACH}
            className={classes.radioButton}
            control={<Radio color="primary" />}
            label={
              <Typography className={classes.text}>
                ACH &nbsp; &nbsp; &nbsp;
                {formFields.accountAccess === AccountAccess.ACH && (
                  <Typography className={classes.primaryColor}>
                    (Great choice. You can link a bank account later after you
                    submit your application)
                  </Typography>
                )}
              </Typography>
            }
          />
          <FormControlLabel
            value={AccountAccess.Wire}
            className={classes.radioButton}
            control={<Radio color="primary" />}
            label="Wire"
          />
        </RadioGroup>
      </FormControl>
      {formFields.accountAccess === AccountAccess.Wire && (
        <FormControl className={classes.wireForm} component="fieldset">
          <TextField
            id="bankABA"
            name="bankABA"
            label="Bank ABA"
            variant="outlined"
            required
            className={classes.wireTextField}
            InputLabelProps={{
              className: classes.wireTextFieldInput,
            }}
            size="small"
            inputProps={{ maxLength: 9 }}
            error={errors.bankABA}
            value={formFields.bankABA}
            onChange={(e) => handleValueChange(e, types[e.target.name])}
            onBlur={handleOnBlur}
          />
          <TextField
            id="beneficiaryAccountName"
            name="beneficiaryAccountName"
            label="Beneficiary Account Name"
            variant="outlined"
            required
            className={classes.wireTextField}
            InputLabelProps={{
              className: classes.wireTextFieldInput,
            }}
            size="small"
            inputProps={{ maxLength: 24 }}
            error={errors.beneficiaryAccountName}
            value={formFields.beneficiaryAccountName}
            onChange={(e) => handleValueChange(e, types[e.target.name])}
            onBlur={handleOnBlur}
          />
          <TextField
            id="beneficiaryAccountNumber"
            name="beneficiaryAccountNumber"
            label="Beneficiary Account Number"
            variant="outlined"
            required
            className={classes.wireTextField}
            InputLabelProps={{
              className: classes.wireTextFieldInput,
            }}
            size="small"
            inputProps={{ maxLength: 20 }}
            error={errors.beneficiaryAccountNumber}
            value={formFields.beneficiaryAccountNumber}
            onChange={(e) => handleValueChange(e, types[e.target.name])}
            onBlur={handleOnBlur}
          />
          <TextField
            id="ffcffbName"
            name="ffcffbName"
            label="FFC/FFB Name (Optional)"
            variant="outlined"
            className={classes.wireTextField}
            InputLabelProps={{
              className: classes.wireTextFieldInput,
            }}
            size="small"
            value={formFields.ffcffbName}
            onChange={(e) => handleValueChange(e, types[e.target.name])}
          />
          <TextField
            id="ffcffbName2"
            name="ffcffbName2"
            label="FFC/FFB Name (Optional)"
            variant="outlined"
            className={classes.wireTextField}
            InputLabelProps={{
              className: classes.wireTextFieldInput,
            }}
            size="small"
            value={formFields.ffcffbName2}
            onChange={(e) => handleValueChange(e, types[e.target.name])}
          />
        </FormControl>
      )}
      <hr className={classes.divider} />
      <div className={classes.footer}>
        <Typography className={classes.title}>
          {ACCOUNT_ACCESS_FOOTER_TITLE}
        </Typography>
        <br />
        {isAdvisor ? (
          <div>
            <Typography className={classes.colorTextGrey}>
              {ACCOUNT_ACCESS_ADVISOR}
            </Typography>
            <br />
            <Typography>
              <Typography className={classes.colorTextGrey}>
                Advisor:
              </Typography>{" "}
              Kyle McAndrew
            </Typography>
            <Typography>
              <Typography className={classes.colorTextGrey}>Phone:</Typography>{" "}
              948-635-6548
            </Typography>
            <Typography>
              <Typography className={classes.colorTextGrey}>Email:</Typography>
              kmcAcndrew@avalonadvisors.com
            </Typography>
          </div>
        ) : (
          <Typography className={classes.colorTextGrey}>
            {ACCOUNT_ACCESS_NONADVISOR}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default AccountAccessForm;
